@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;700;900&display=swap");
@import url("./assets/css/style.bundle.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 10px;
}
#root {
  height: 100%;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  /* border-color: #009ef7; */
  background-color: #009ef7;
  color: #fff;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0.475rem 0.475rem 0 0;
}
.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-remove:focus {
  color: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #1890ff;
  background-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom
  > div
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top
  > div
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: 20px;
}

.table-striped-rows {
  border: 1px solid #f0f0f0;
}
.table-striped-rows .ant-table-cell {
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}

.scoring {
  width: 240px;
}
.score-law {
  background: rgb(176, 225, 125);
}
.score-law-middle {
  background: rgb(254, 191, 77);
}
.score-high {
  background: rgb(173, 77, 77);
}
.score-high-middle {
  background: rgb(244, 124, 124);
}
.scoring-line {
  height: 48px;
  top: -4px;
}
.avatar .ant-avatar-string {
  width: 100%;
  height: 100%;
  transform: scale(1) translateX(-50%) !important;
}
.avatar-img {
  width: 100%;
  height: 100%;
}
.avatar-img:after {
  content: attr(alt);
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #a1a5b7;
}

.organizations-list {
  display: flex;
  width: 220px;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: auto auto 0 auto;
  background: #1e1e2d;
}
.organizations-list .title {
  text-align: center;
  padding-top: 10px;
  font-weight: 600;
  color: #fff;
}
.organizations-list .stop {
  position: absolute;
  z-index: 1;
}
.organizations-list .figure {
  margin: 0;
  width: 186px;
  height: 200px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}
.partners img {
  object-fit: contain;
}

input[type='number'] {
  -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.supplier-info .ant-modal-content{
  background: #f5f8fa;
}
